/* src/index.css */

:root {
  --background-blur-md: ;
  --basewhite: rgba(255, 255, 255, 1);
  --blue-200: rgba(178, 221, 255, 1);
  --blue-50: rgba(239, 248, 255, 1);
  --blue-500: rgba(46, 144, 250, 1);
  --blue-600: rgba(21, 112, 239, 1);
  --blue-700: rgba(23, 92, 211, 1);
  --blue-light200: rgba(185, 230, 254, 1);
  --blue-light50: rgba(240, 249, 255, 1);
  --blue-light500: rgba(11, 165, 236, 1);
  --blue-light600: rgba(0, 134, 201, 1);
  --blue-light700: rgba(2, 106, 162, 1);
  --display-sm-semibold-font-family: "AlbertSans-SemiBold", Helvetica;
  --display-sm-semibold-font-size: 30px;
  --display-sm-semibold-font-style: normal;
  --display-sm-semibold-font-weight: 600;
  --display-sm-semibold-letter-spacing: 0px;
  --display-sm-semibold-line-height: 38px;
  --display-xs-semibold-font-family: "AlbertSans-SemiBold", Helvetica;
  --display-xs-semibold-font-size: 24px;
  --display-xs-semibold-font-style: normal;
  --display-xs-semibold-font-weight: 600;
  --display-xs-semibold-letter-spacing: 0px;
  --display-xs-semibold-line-height: 32px;
  --error-100: rgba(254, 228, 226, 1);
  --error-200: rgba(254, 205, 202, 1);
  --error-25: rgba(255, 251, 250, 1);
  --error-300: rgba(253, 162, 155, 1);
  --error-50: rgba(254, 243, 242, 1);
  --error-500: rgba(240, 68, 56, 1);
  --error-600: rgba(217, 45, 32, 1);
  --error-700: rgba(180, 35, 24, 1);
  --error-800: rgba(145, 32, 24, 1);
  --focus-ring-4px-gray-100: 0px 0px 0px 4px rgba(242, 244, 247, 1);
  --focus-ring-4px-gray-500: 0px 0px 0px 4px rgba(102, 112, 133, 1);
  --focus-ring-4px-primary-100: 0px 0px 0px 4px rgba(244, 235, 255, 1);
  --focus-ring-4px-primary-500: 0px 0px 0px 4px rgba(158, 119, 237, 1);
  --gray-100: rgba(242, 244, 247, 1);
  --gray-200: rgba(234, 236, 240, 1);
  --gray-25: rgba(252, 252, 253, 1);
  --gray-300: rgba(208, 213, 221, 1);
  --gray-400: rgba(152, 162, 179, 1);
  --gray-50: rgba(249, 250, 251, 1);
  --gray-500: rgba(102, 112, 133, 1);
  --gray-600: rgba(71, 84, 103, 1);
  --gray-700: rgba(52, 64, 84, 1);
  --gray-800: rgba(29, 41, 57, 1);
  --gray-900: rgba(16, 24, 40, 1);
  --gray-950: rgba(12, 17, 29, 1);
  --gray-blue200: rgba(213, 217, 235, 1);
  --gray-blue50: rgba(248, 249, 252, 1);
  --gray-blue500: rgba(78, 91, 166, 1);
  --gray-blue600: rgba(62, 71, 132, 1);
  --gray-blue700: rgba(54, 63, 114, 1);
  --indigo-200: rgba(199, 215, 254, 1);
  --indigo-50: rgba(238, 244, 255, 1);
  --indigo-500: rgba(97, 114, 243, 1);
  --indigo-600: rgba(68, 76, 231, 1);
  --indigo-700: rgba(53, 56, 205, 1);
  --orange-200: rgba(249, 219, 175, 1);
  --orange-50: rgba(254, 246, 238, 1);
  --orange-500: rgba(239, 104, 32, 1);
  --orange-600: rgba(224, 79, 22, 1);
  --orange-700: rgba(185, 56, 21, 1);
  --pink-200: rgba(252, 206, 238, 1);
  --pink-50: rgba(253, 242, 250, 1);
  --pink-500: rgba(238, 70, 188, 1);
  --pink-600: rgba(221, 37, 144, 1);
  --pink-700: rgba(193, 21, 116, 1);
  --primary-100: rgba(244, 235, 255, 1);
  --primary-200: rgba(233, 215, 254, 1);
  --primary-25: rgba(252, 250, 255, 1);
  --primary-300: rgba(214, 187, 251, 1);
  --primary-50: rgba(249, 245, 255, 1);
  --primary-500: rgba(158, 119, 237, 1);
  --primary-600: rgba(127, 86, 217, 1);
  --primary-700: rgba(105, 65, 198, 1);
  --primary-800: rgba(83, 56, 158, 1);
  --purple-200: rgba(217, 214, 254, 1);
  --purple-50: rgba(244, 243, 255, 1);
  --purple-500: rgba(122, 90, 248, 1);
  --purple-600: rgba(105, 56, 239, 1);
  --purple-700: rgba(89, 37, 220, 1);
  --shadow-lg: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  --shadow-sm: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  --shadow-sm-focused-4px-gray-100: 0px 0px 0px 4px rgba(242, 244, 247, 1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  --shadow-xl: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  --shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --shadow-xs-focused-4px-error-100: 0px 0px 0px 4px rgba(254, 228, 226, 1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --shadow-xs-focused-4px-gray-100: 0px 0px 0px 4px rgba(242, 244, 247, 1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --shadow-xs-focused-4px-primary-100: 0px 0px 0px 4px rgba(244, 235, 255, 1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --success-100: rgba(220, 250, 230, 1);
  --success-200: rgba(171, 239, 198, 1);
  --success-50: rgba(236, 253, 243, 1);
  --success-500: rgba(23, 178, 106, 1);
  --success-600: rgba(7, 148, 85, 1);
  --success-700: rgba(6, 118, 71, 1);
  --text-lg-medium-font-family: "AlbertSans-Medium", Helvetica;
  --text-lg-medium-font-size: 18px;
  --text-lg-medium-font-style: normal;
  --text-lg-medium-font-weight: 500;
  --text-lg-medium-letter-spacing: 0px;
  --text-lg-medium-line-height: 28px;
  --text-lg-semibold-font-family: "AlbertSans-SemiBold", Helvetica;
  --text-lg-semibold-font-size: 18px;
  --text-lg-semibold-font-style: normal;
  --text-lg-semibold-font-weight: 600;
  --text-lg-semibold-letter-spacing: 0px;
  --text-lg-semibold-line-height: 28px;
  --text-md-medium-font-family: "AlbertSans-Medium", Helvetica;
  --text-md-medium-font-size: 16px;
  --text-md-medium-font-style: normal;
  --text-md-medium-font-weight: 500;
  --text-md-medium-letter-spacing: 0px;
  --text-md-medium-line-height: 24px;
  --text-md-regular-font-family: "AlbertSans-Regular", Helvetica;
  --text-md-regular-font-size: 16px;
  --text-md-regular-font-style: normal;
  --text-md-regular-font-weight: 400;
  --text-md-regular-letter-spacing: 0px;
  --text-md-regular-line-height: 24px;
  --text-md-semibold-font-family: "AlbertSans-SemiBold", Helvetica;
  --text-md-semibold-font-size: 16px;
  --text-md-semibold-font-style: normal;
  --text-md-semibold-font-weight: 600;
  --text-md-semibold-letter-spacing: 0px;
  --text-md-semibold-line-height: 24px;
  --text-sm-medium-font-family: "AlbertSans-Medium", Helvetica;
  --text-sm-medium-font-size: 14px;
  --text-sm-medium-font-style: normal;
  --text-sm-medium-font-weight: 500;
  --text-sm-medium-letter-spacing: 0px;
  --text-sm-medium-line-height: 20px;
  --text-sm-regular-font-family: "AlbertSans-Regular", Helvetica;
  --text-sm-regular-font-size: 14px;
  --text-sm-regular-font-style: normal;
  --text-sm-regular-font-weight: 400;
  --text-sm-regular-letter-spacing: 0px;
  --text-sm-regular-line-height: 20px;
  --text-sm-semibold-font-family: "AlbertSans-SemiBold", Helvetica;
  --text-sm-semibold-font-size: 14px;
  --text-sm-semibold-font-style: normal;
  --text-sm-semibold-font-weight: 600;
  --text-sm-semibold-letter-spacing: 0px;
  --text-sm-semibold-line-height: 20px;
  --text-xl-semibold-font-family: "AlbertSans-SemiBold", Helvetica;
  --text-xl-semibold-font-size: 20px;
  --text-xl-semibold-font-style: normal;
  --text-xl-semibold-font-weight: 600;
  --text-xl-semibold-letter-spacing: 0px;
  --text-xl-semibold-line-height: 30px;
  --text-xs-medium-font-family: "AlbertSans-Medium", Helvetica;
  --text-xs-medium-font-size: 12px;
  --text-xs-medium-font-style: normal;
  --text-xs-medium-font-weight: 500;
  --text-xs-medium-letter-spacing: 0px;
  --text-xs-medium-line-height: 18px;
  --text-xs-regular-font-family: "AlbertSans-Regular", Helvetica;
  --text-xs-regular-font-size: 12px;
  --text-xs-regular-font-style: normal;
  --text-xs-regular-font-weight: 400;
  --text-xs-regular-letter-spacing: 0px;
  --text-xs-regular-line-height: 18px;
  --text-xs-semibold-font-family: "AlbertSans-SemiBold", Helvetica;
  --text-xs-semibold-font-size: 12px;
  --text-xs-semibold-font-style: normal;
  --text-xs-semibold-font-weight: 600;
  --text-xs-semibold-letter-spacing: 0px;
  --text-xs-semibold-line-height: 18px;
  --warning-100: rgba(254, 240, 199, 1);
  --warning-200: rgba(254, 223, 137, 1);
  --warning-50: rgba(255, 250, 235, 1);
  --warning-500: rgba(247, 144, 9, 1);
  --warning-600: rgba(220, 104, 3, 1);
  --warning-700: rgba(181, 71, 8, 1);
}
